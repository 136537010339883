<template>
    <v-row class="fill-height">
        <v-col>
            <v-card>
                <v-card-title>
                    <v-icon>mdi-hair-dryer-outline</v-icon>
                    Dados do Serviço.
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        :to="{
                            name: 'usersCreate',
                            params: { id: service.id }
                        }"
                    >
                        <v-icon left>mdi-pencil</v-icon>
                        Editar
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col md="4" sm="6" xs="12">
                                <b>Nome:</b> {{ service.name }}
                            </v-col>
                            <v-col md="4" sm="6" xs="12">
                                <b>Documento:</b> {{ service.desc }}
                            </v-col>
                            <v-col md="4" sm="6" xs="12">
                                <b>Tempo:</b> {{ service.value }} min.
                            </v-col>
                            <v-col md="4" sm="6" xs="12">
                                <b>Comissão sobre serviço:</b>
                                {{ service.categoryName }} %
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-alert type="warning" outlined v-show="!service"
                    >Nenhum serviço encontrado.</v-alert
                >
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import { SERVICE_GET } from "@/store/actions/service.type";
import { SERVICE_RESET_STATE } from "@/store/mutations/service.type";

export default {
    name: "service",
    directives: { mask },
    props: {
        id: {
            default: 0,
            type: Number,
            required: true
        }
    },

    components: {},
    async beforeRouteUpdate(to, from, next) {
        // Reset state if user goes from /editor/:id to /editor
        // The component is not recreated so we use to hook to reset the state.
        await store.dispatch(SERVICE_RESET_STATE);
        return next();
    },
    async beforeRouteEnter(to, from, next) {
        Promise.all([
            await store.dispatch(SERVICE_RESET_STATE),
            await store.dispatch(SERVICE_GET, to.params.id)
        ]).then(() => {
            next();
        });
    },
    data: () => ({
        //Lista de Usuários
        pagination: {
            sortBy: "name"
        },
        search: "",
        selected: [],
        formIsValid: false
    }),
    methods: {
        //Methods Lista Usuarios
        toggleAll() {
            if (this.selected.length) this.selected = [];
            else this.selected = this.dados.slice();
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        buscar() {
            // store.dispatch(USER_SEARCH);
            // console.log("buscar");
        },
        //Fim Methods Lista Usuarios

        reset() {
            this.$refs.formUsuario.reset();
        }
    },
    mounted() {},
    computed: {
        ...mapGetters(["checkUsers", "service"])
    },
    watch: {}
};
</script>
